import React from 'react';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {

  },
  sig: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
  },
  paperOptions: {
    background: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(0vw, -10vh)'
  },
  options: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,

  },
  paperTop: {
    height: '37vh',
    width: 'auto'
  },
  top: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  toolbarLink: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  choices: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    top: '20px',
  },
}));


const Top = ({ Image, toSkill, toEducation, toExperience, post, toLanding }) => {
  const classes = useStyles();

  const sections = [
    { title: 'Skills', onClick: () => toSkill() },
    { title: 'Experience', onClick: () => toExperience() },
    { title: 'About', onClick: () => toEducation() },
  ];

  return (



    <Container maxWidth="lg">


      <Grid container spacing={3}>

        <Grid item sm={12}>
          <Paper class={classes.paperTop}>
            <img src={Image} className={classes.sig}
              onClick={() => toLanding()}
            ></img>
          </Paper>
        </Grid>


          </Grid>


              <Paper className={classes.paperOptions} elevation={0}>
                <div className={classes.options}
                  style={{
                    fontSize: '17px',
                    fontFamily: 'Franklin Gothic Medium',
                    fontWeight: '900',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                >

                  <Link
                    color="inherit"
                    key={sections[0].title}
                    className={classes.toolbarLink}
                    onClick={sections[0].onClick}
                  >
                    {sections[0].title}
                  </Link>

                  <Link
                    color="inherit"
                    key={sections[1].title}
                    className={classes.toolbarLink}
                    onClick={sections[1].onClick}
                  >
                    {sections[1].title}
                  </Link>


                  <Link
                    color="inherit"
                    key={sections[2].title}
                    className={classes.toolbarLink}
                    onClick={sections[2].onClick}
                  >
                    {sections[2].title}
                  </Link>

                </div>


              </Paper>

    </Container>


  );
}

export default Top;