import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Trans from './skills/transHead.png'
import Left from './skills/left.png'
import Right from './skills/right.png'
import Popover from '@material-ui/core/Popover';
import Top from './../landing/Top';
import Container from '@material-ui/core/Container';
import zIndex from '@material-ui/core/styles/zIndex';
import Monitor from './skills/tech.png';
import Art from './skills/art.png';
import Typography from '@material-ui/core/Typography';
import Footer from './Footer';
import CssBaseline from '@material-ui/core/CssBaseline';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    slot: {
        width: '50%',
        height: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '35vh',

    },
    popover: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '50vh',
    },
    img: {
        objectFit: 'contain',
        position: 'relative',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
        height: '50vh',
        width: '100%',
    },
    parent: {
        position: 'relative',
        top: '0',
        left: '0',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    image1: {
        width: '50%',
        height: 'auto',
        position: 'relative',
        top: '0',
        left: '0',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        pointerEvents: 'none',
        transform: 'scale(2) translate(2%, 14%)'
    },
    image2: {
        width: '100%',
        height: 'auto',
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        transform: 'scale(.13) translate(-48%, -325%)',
    },

    image3: {
        width: '100%',
        height: 'auto',
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        transform: 'scale(.125) translate(52%, -342%)',
    },

    tech: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        pointerEvents: 'none',
        transform: 'scale(.4) translate(-85%, -138%)'
    },

    art: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        pointerEvents: 'none',
        transform: 'scale(.6) translate(60%, -120%)'
    },

}));

const RenderSkill = (view) => {
    const classes = useStyles();
    const viewEnum = {
        NONE: 0,
        LEFT: 1,
        RIGHT: 2,
    }
    if (view === viewEnum.LEFT) {

        return (
            <main>

                <div>
                    <img src={Monitor} class={classes.tech} />
                </div>

            </main>

        );

    }

    else if (view === viewEnum.RIGHT) {

        return (
            <main>


                <img src={Art} class={classes.art} />

            </main>



        )

    }
}



const Skill = ({ Image, toSkill, toEducation, toExperience, toLanding }) => {
    const classes = useStyles();


    const viewEnum = {
        NONE: 0,
        LEFT: 1,
        RIGHT: 2,
    }

    const [view, setView] = useState(viewEnum.NONE);

    console.log(view);
    return (
        <div>
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">

                    <Top
                        toSkill={() => toSkill()}
                        toEducation={() => toEducation()}
                        toExperience={() => toExperience()}
                        toLanding={() => toLanding()}
                        Image={Image}
                    />



                    <div class={classes.parent}>
                        <img src={Left} class={classes.image2} onClick={() => setView(viewEnum.LEFT)} style={{
                            cursor: 'pointer'
                        }} />
                        <img src={Right} class={classes.image3} onClick={() => setView(viewEnum.RIGHT)} style={{
                            cursor: 'pointer'
                        }} />
                        <img src={Trans} class={classes.image1} />
                    </div>




                    {RenderSkill(view)}


                </Container>
                <Footer />
            </React.Fragment>
        </div>
    );
}






export default Skill;