import React, { Component } from 'react';
import logo from './logo.svg';
import Landing from './containers/landing/Landing';
import Skill from './containers/Skill/Skill';
import Image from './signature.png';
import Experience from './containers/Experience/Experience';
import About from './containers/About/About';

const viewEnum = {
  LANDING: '1',
  SKILL: '2',
  ABOUT: '3',
  EXPERIENCE: '4',
}

class Layout extends Component {


  constructor(props) {
    super(props);
    this.state = {
      layoutView: window.sessionStorage.getItem('layoutView') || viewEnum.LANDING,
    }
  }

//  clearStorage = () => {
//    window.sessionStorage.clear();
//  }
//
//  componentDidMount() {
//    window.addEventListener("beforeunload", this.clearStorage)
//  }
//
//  componentWillUnmount() {
//    window.removeEventListener("beforeunload", this.clearStorage)
//  }
//

  toSkill = () => {
    this.setState({ layoutView: viewEnum.SKILL });
    window.sessionStorage.setItem('layoutView', viewEnum.SKILL);
  }

  toLanding = () => {
    this.setState({ layoutView: viewEnum.LANDING });
    window.sessionStorage.setItem('layoutView', viewEnum.LANDING);
  }


  toEducation = () => {
    this.setState({ layoutView: viewEnum.ABOUT });
    window.sessionStorage.setItem('layoutView', viewEnum.ABOUT);
  }

  toExperience = () => {
    this.setState({ layoutView: viewEnum.EXPERIENCE });
    window.sessionStorage.setItem('layoutView', viewEnum.EXPERIENCE);
  }

  render() {
    let view = null;

    switch (this.state.layoutView) {
      case viewEnum.LANDING:
        view = <Landing 
                toSkill={this.toSkill.bind(this)} 
                toEducation={this.toEducation.bind(this)} 
                toExperience={this.toExperience.bind(this)}
                toLanding={this.toLanding.bind(this)}
                Image={Image}
                />;
        break;
      case viewEnum.SKILL:
        view = <Skill 
                toLanding={this.toLanding.bind(this)} 
                toSkill={this.toSkill.bind(this)} 
                toEducation={this.toEducation.bind(this)} 
                toExperience={this.toExperience.bind(this)}
                Image={Image}
               />;
        break;
      case viewEnum.EXPERIENCE:
        view = <Experience
                toSkill={this.toSkill.bind(this)} 
                toEducation={this.toEducation.bind(this)} 
                toExperience={this.toExperience.bind(this)}
                toLanding={this.toLanding.bind(this)}
                Image={Image}
                />;
        break;
      case viewEnum.ABOUT:
        view = <About
                toSkill={this.toSkill.bind(this)} 
                toEducation={this.toEducation.bind(this)} 
                toExperience={this.toExperience.bind(this)}
                toLanding={this.toLanding.bind(this)}
                Image={Image}
                />;
        break;

    }


    return (
      <div>

        <main>
          {view}
        </main>

      </div>
    );

  }
}

export default Layout;
