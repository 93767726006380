import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Top from './Top';
import Footer from './Footer';
import Olosa from './pics/OlosasLagoonFinal.png';
import Glasses from './pics/glassesFinal.png';
import Ghost from './pics/ghostDude.png';
import Fish from './pics/fishy.png';
import Cater from './pics/caterFinal.png';
import Fly from './pics/fly.png';
import Shell from './pics/shellFinal.png';
import Fire from './pics/fireFly.png';
import Lizard from './pics/finalLizard.png';
import Expand from './Expand';
import ExpandFish from './ExpandFish';
import FishView from './pics/fishView.png'
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '35vh',

  },
  text: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  paperBottom: {
    transform: 'translate(0%, 0%)',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "15vh",
    width: 'auto',
    background: theme.palette.background.default
  },
}));

const sections = [
  { title: 'Technology', url: '#' },
  { title: 'Design', url: '#' },
  { title: 'Culture', url: '#' },
  { title: 'Business', url: '#' },
  { title: 'Politics', url: '#' },
  { title: 'Opinion', url: '#' },
  { title: 'Science', url: '#' },
  { title: 'Health', url: '#' },
  { title: 'Style', url: '#' },
  { title: 'Travel', url: '#' },
];



const Landing = ({ Image, toSkill, toExperience, toEducation, toLanding }) => {
  const classes = useStyles()



  return (
    <div>

      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <main>
            <Top
              toSkill={() => toSkill()}
              toEducation={() => toEducation()}
              toExperience={() => toExperience()}
              toLanding={() => toLanding()}
              Image={Image}
            />

            <Grid container spacing={3}>

              <Grid item xs={6} sm={12}>
                <Paper elevation={0} className={classes.paperBottom}>

                  <div
                    class={classes.text}
                    style={{
                      fontSize: '.9vw',
                      fontFamily: 'Franklin Gothic Medium',
                      fontWeight: '300',
                      textAlign: 'center',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                      To improve my competency as a developer, I decided to refine my skills as a 3D character artist. 
                      Throughout this process I used Blender, Maya and Substance Painter. 
                      I then began talking to various artists in the Blender community about missing functionality in the software. 
                      Currently I am working on a tool in Blender to copy the characteristics (drivers, constraints, bone scaling inheritance, etc) from bones in one rig to bones in another rig on the basis of bone name. 
                      My focus on art and software development gives me the ability to better communicate with software engineers and artists. 


                                </div>

                </Paper>

              </Grid>

              <Grid item xs={6} sm={4}>


                <Expand
                  image={Olosa}
                  url="https://vimeo.com/495361556"
                  background="#1d1d1d"
                />

              </Grid>


              <Grid item xs={6} sm={4}>


                <Expand
                  image={Glasses}
                  url="https://vimeo.com/495361463"
                  background='#e6ab73'
                />

              </Grid>

              <Grid item xs={6} sm={4}>

                <Expand
                  image={Ghost}
                  url="https://vimeo.com/495361436"
                  background='#262626'
                />

              </Grid>



              <Grid item xs={6} sm={4}>
                <ExpandFish
                  image={Fish}
                  url="https://vimeo.com/495362026"
                  background='#FFFFFF'
                  imageTwo={FishView}
                />
              </Grid>


              <Grid item xs={6} sm={4}>
                <Expand
                  image={Cater}
                  url="https://vimeo.com/495361219"
                  background='#474747'
                />
              </Grid>


              <Grid item xs={6} sm={4}>
                <Expand
                  image={Shell}
                  url="https://vimeo.com/495361583"
                  background='#f1c4de'
                />
              </Grid>


              <Grid item xs={6} sm={4}>
                <Expand
                  image={Fly}
                  url="https://vimeo.com/495361357"
                  background='#fff3a9'
                />
              </Grid>





              <Grid item xs={6} sm={4}>
                <Expand
                  image={Fire}
                  url="https://vimeo.com/495361482"
                  background='#c79be2'
                />
              </Grid>


              <Grid item xs={6} sm={4}>
                <Expand
                  image={Lizard}
                  url="https://vimeo.com/495361499"
                  background='#cf9884'
                />
              </Grid>



            </Grid>

          </main>
        </Container>
        <Footer />
      </React.Fragment>

    </div >
  );
}

export default Landing;