import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Vimeo from '@u-wave/react-vimeo';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },

    popover: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '50vh',
    },
    img: {
        objectFit: 'contain',
        position: 'relative',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
        height: '50vh',
        width: '100%',
    },
    paperVid: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "auto",
        width: 'auto',
        transform: 'translate(0vw, 12vh)'
    },
    video: {
        position: 'relative',
        overflow: 'hidden',
        iframe: {
            position: 'absolute',
            width: '100%',
            height: '100%',
        }
    },
    slot: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '35vh',

    },
    text: {
        transform: 'translate(20vw, -51vh)',
        position: 'relative',
        top: '0',
        left: '0',
        marginLeft: 'auto',
        marginRight: '5%',
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },


}));

const Expand = ({ image, url, background, }) => {
    const classes = useStyles();

    let pageCenter = document.createElement("center");

    pageCenter.style.position = 'fixed';
    pageCenter.style.top = '50%';
    pageCenter.style.left = '50%';

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = () => {
        setAnchorEl(pageCenter);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (

        <main>

            <Button onClick={handleClick}>
                <Paper elevation={3} className={classes.paper} style={{
                    background: background,
                }}>

                    <img src={image} className={classes.slot}></img>

                </Paper>
            </Button>
            <Popover

                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{
                    transform: 'translate(8%, 11%)',
                    height: 'auto',
                    width: 'auto',
                }}
                PaperProps={{
                    style: {
                        height: '53vh',
                        width: '45vw',
                        transform: 'scale(1.8, 1.8) translate(0vw, -6vh)'
                    },
                }}
            >

                <Grid container spacing={3}>


                    <Grid item xs={6} sm={4}>
                        <img src={image} className={classes.img}></img>
                    </Grid>

                    <Grid item xs={6} sm={8}>
                    
                    <Paper elevation={0} className={classes.paperVid}>
                        <div className={classes.video}>

                            <Vimeo
                                video={url}
                                autoplay={true}
                                controls={false}
                                showByline={false}
                                loop={true}
                                showTitle={false}
                                background={true}
                                responsive={true}
                            />

                        </div >
                    </Paper>



                    </Grid>

                </Grid>
                        <div
                            class={classes.text}
                            style={{
                                fontSize: '10px',
                                fontFamily: 'Franklin Gothic Medium',
                                fontWeight: '300',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                        >
                            BACK
                        </div>
            </Popover>

        </main>

    );
}

export default Expand;