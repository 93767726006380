import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import Top from './../landing/Top';
import Me from './pics/me.jpg';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Vimeo from '@u-wave/react-vimeo';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: 'relative'
    },
    text: {
        objectFit: 'contain',
        width: '100%',
        height: '100%',
    },
    img: {
        objectFit: 'contain',
        position: 'relative',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
        transform: 'scale(1) translate(-1.2%, -5%)',
        height: '50vh',
        width: '100%',
    },
    footer: {
        transform: 'translate(0%, 550%)',
        position: 'relative',
        top: '0',
        left: '0',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    paper: {
        transform: 'translate(0%, 40%)',
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "15vh",
        width: 'auto',
        background: theme.palette.background.default
    },
    video: {
        transform: 'scale(.7)'
    }
}));

const About = ({ Image, toSkill, toEducation, toExperience, toLanding }) => {
    const classes = useStyles();

    return (
        <div>
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">

                    <Top
                        toSkill={() => toSkill()}
                        toEducation={() => toEducation()}
                        toExperience={() => toExperience()}
                        toLanding={() => toLanding()}
                        Image={Image}
                    />

                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <img src={Me} className={classes.img}></img>
                        </Grid>

                        <Grid item xs={6}>
                            <Paper elevation={0} className={classes.paper}>

                                <div
                                    class={classes.text}
                                    style={{
                                        fontSize: '.9vw',
                                        fontFamily: 'Franklin Gothic Medium',
                                        fontWeight: '300',
                                        textAlign: 'center',
                                        whiteSpace: 'pre-wrap'
                                    }}
                                >
                                    
                                    A few months ago I ran into some performance issues while rigging a character in Blender and happened to read an article by Daniel S. Lima on the ChopRig System at Blue Sky Studios. 
                                    The article talked about a multi-threaded approach to the rigging system in Maya. 




                                </div>

                                <div>


                                </div>

                                <div
                                    class={classes.text}
                                    style={{
                                        fontSize: '.9vw',
                                        fontFamily: 'Franklin Gothic Medium',
                                        fontWeight: '300',
                                        textAlign: 'center',
                                        whiteSpace: 'pre-wrap'
                                    }}
                                >
                                    


                                    This motivated me to take a Parallel Programming course. 
                                    In the course I created programs to best utilize the resources on a machine in both C++ and Python. 
                                    This knowledge will help me to capitalize on the multi-core capabilities within the Blue Sky Studios desktop architecture. 
                                    In my Computer Graphics class, I built an image processor, ray-tracer and rendering engine. 
                                    This has given me a foundational understanding of the backend animation pipeline.
                                    With the skills I learned while earning my Bachelors Degree in Computer Science, as well as the experience gained becoming a 3D artist and game developer, 
                                    I believe I will be a productive software engineer in the animation industry.


                                </div>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={classes.video}>
                                <Vimeo
                                    video={"https://vimeo.com/497347724"}
                                    autoplay={false}
                                    controls={false}
                                    showByline={false}
                                    loop={true}
                                    showTitle={false}
                                    background={true}
                                    responsive={true}
                                />

                            </div>
                        </Grid>

                    </Grid>
                </Container>
                <Footer />
            </React.Fragment>
        </div>
    );
}

export default About;