import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import Top from './../landing/Top';
import Vimeo from '@u-wave/react-vimeo';
import Work from './pics/workflow.png';
import Brain from './pics/brainMap.png';
import Comp from './pics/comp.png';
import Py from './pics/python.png';
import Footer from './Footer';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paperBottom: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        height: "100%",
        width: 'auto',
        transform: 'translate(0%, 5%)',
    },
    paperAPL: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        height: "100%",
        width: 'auto',
        transform: 'translate(0%, 5%)',
    },
    text: {
        transform: 'translate(0%, -10%)',
        position: 'relative',
        top: '0',
        left: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(7),
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text2: {
        transform: 'translate(0%, 50%)',
        position: 'relative',
        top: '0',
        left: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(15),
        marginTop: theme.spacing(12),
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    vid: {
        position: 'relative',
        overflow: 'hidden',
        iframe: {
            position: 'absolute',
            width: '100%',
            height: '100%',
        }
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "100%",
        width: 'auto',
    },
    img: {
        objectFit: 'contain',
        width: '100%',
        height: '100%',
    },
    text3: {
        transform: 'translate(0%, 150%)',
        position: 'relative',
        top: '0',
        left: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(25),
        marginTop: theme.spacing(13),
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 'auto'
    },
    slot: {
        objectFit: 'contain',
        width: '100%',
        height: '100%',
    },
    footer: {
        transform: 'translate(0%, 550%)',
        position: 'relative',
        top: '0',
        left: '0',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
    }
}));

const Experience = ({ Image, toSkill, toEducation, toExperience, toLanding }) => {
    const classes = useStyles();

    return (

      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">

            <Top
                toSkill={() => toSkill()}
                toEducation={() => toEducation()}
                toExperience={() => toExperience()}
                toLanding={() => toLanding()}
                Image={Image}
            />

            <div className={classes.root}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <div
                            class={classes.text}
                            style={{
                                fontSize: '17px',
                                fontFamily: 'Franklin Gothic Medium',
                                fontWeight: '300',
                                textAlign: 'center'
                            }}
                        >
                            A large portion of the creatures on the home page are assets for a game I'm creating in Unity. I start with 3D models, then I output different animations as 2D
                            sprite sheets to be used in Unity. I then matched the different movements with the appropriate animations using C# scripts. I then had all of the enemies follow the characters' updating position.
                            This is still early in development and the assets used below are just placeholders I created.

                </div>
                    </Grid>

                    <Grid item xs={6}>
                        <Paper className={classes.paper}>

                            <div className={classes.vid}>

                                <Vimeo
                                    video={'https://vimeo.com/495361307'}
                                    autoplay={true}
                                    controls={false}
                                    showByline={false}
                                    loop={true}
                                    showTitle={false}
                                    background={true}
                                    responsive={true}
                                />

                            </div >


                        </Paper>
                    </Grid>

                    <Grid item xs={6}>
                        <Paper className={classes.paper}>

                            <div className={classes.vid}>
                                <Vimeo
                                    video={'https://vimeo.com/495361400'}
                                    autoplay={true}
                                    controls={false}
                                    showByline={false}
                                    loop={true}
                                    showTitle={false}
                                    background={true}
                                    responsive={true}
                                />
                            </div >
                        </Paper>
                    </Grid>


                    <Grid item xs={12}>
                        <div
                            class={classes.text2}
                            style={{
                                fontSize: '17px',
                                fontFamily: 'Franklin Gothic Medium',
                                fontWeight: '300',
                                textAlign: 'center'
                            }}
                        >
                            Some of the humanoid characters from the home page are from a short I'm creating. 
                            Below is the basic workflow that I've used when making characters for my short. 
                            I also have experience creating different effects and tools with Python scripts in Blender.

                        </div>

                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paperBottom}>

                            <img src={Work} class={classes.img} />

                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            class={classes.text3}
                            style={{
                                fontSize: '17px',
                                fontFamily: 'Franklin Gothic Medium',
                                fontWeight: '300',
                                textAlign: 'center'
                            }}
                        >
                            As a research intern in neuroscience and network security at the JHU Applied Physics Lab, I developed ways to analyze patterns in brain networks. 
                            I learned how to receive feedback, meet deadlines and communicate with scientists from varying fields. 

                        </div>

                    </Grid>

                    <Grid item xs={4}>
                        <Paper className={classes.paperAPL}>
                            <img src={Brain} className={classes.slot}></img>
                        </Paper>

                    </Grid>

                    <Grid item xs={4}>
                        <Paper className={classes.paperAPL}>
                            <img src={Comp} className={classes.slot}></img>
                        </Paper>



                    </Grid>

                    <Grid item xs={4}>
                        <Paper className={classes.paperAPL}>
                            <img src={Py} className={classes.slot}></img>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            class={classes.footer}
                        >
                            <Footer />
                        </div>
                    </Grid>
                </Grid>
            </div>

        </Container>
      </React.Fragment>
    );
}

export default Experience;